import "../assets/css/cookieconsent.css"
import * as CookieConsent from "vanilla-cookieconsent";

/**
 * All config. options available here:
 * https://cookieconsent.orestbida.com/reference/configuration-reference.html
 */

/* eslint-disable */ 

export default {
    install: (app, pluginConfig) => {
        
        pluginConfig = {
            onFirstConsent: () => {
                const preferences = CookieConsent.getUserPreferences()
  
                location.reload();
            },
  
            onConsent: ( { cookie } ) => {
                const preferences = CookieConsent.getUserPreferences()
                
            },
  
            onChange:   function ( { cookie, changedCategories, changedServices } ) {
  
                if ( !CookieConsent.acceptedCategory('analytics') ) {
                    console.log("Rechazada analytics")
                    CookieConsent.eraseCookies(['_gid', /^_ga/], '/');
                    CookieConsent.eraseCookies('cc_cookie');
                }
  
                location.reload();
  
                // const preferences = CookieConsent.getUserPreferences()
                // console.log("Preferencias on change", preferences)
            },
            categories: {
                necessary: {
                    enabled: true,  // this category is enabled by default
                    readOnly: true  // this category cannot be disabled
                },
                analytics: {
                    readOnly: false,
                    autoClear: {
                        reloadPage: true,
                        cookies: [
                            {
                                name: /^(_ga)/      //regex
                            },
                            {
                                name: '_gid'        //string
                            }
                        ]
                    }
                }
            },
        
            language: {
                default: 'es',
                translations: {
                    es: {
                        consentModal: {
                            title: 'Usamos cookies',
                            description: 'Este sitio web utiliza cookies propias y de terceros para mejorar nuestros servicios y optimizar su navegación. Puedes aceptar todas las cookies pulsando el botón Aceptar todas o configurarlas pulsando el botón Personalizar cookies.',
                            acceptAllBtn: 'Aceptar todas',
                            acceptNecessaryBtn: 'Rechazar todas',
                            showPreferencesBtn: 'Gestionar preferencias individuales'
                        },
                        preferencesModal: {
                            title: 'Gestionar preferencias de cookies',
                            acceptAllBtn: 'Aceptar todas',
                            acceptNecessaryBtn: 'Rechazar todas',
                            savePreferencesBtn: 'Aceptar selección actual',
                            closeIconLabel: 'Cerrar modal',
                            sections: [
                            
                                {
                                    title: 'Cookies estrictamente necesarias',
                                    description: 'Estas cookies son esenciales para el correcto funcionamiento del sitio web y no pueden ser deshabilitadas.',
        
                                    //this field will generate a toggle linked to the 'necessary' category
                                    linkedCategory: 'necessary'
                                },
                                {
                                    title: 'Rendimiento y Analítica',
                                    description: 'Son aquellas que bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado.',
                                    linkedCategory: 'analytics'
                                }
                           
                            ]
                        }
                    }
                }
            }
        }
        app.config.globalProperties.$CookieConsent = CookieConsent;
        app.config.globalProperties.$CookieConsent.run(pluginConfig);
    }
}