export default {
  mas_info: "More Info",
  restaurantes: "Restaurants",
  alojamientos: "Lodging",
  informacion: "Information",
  propuesta: "Proposal",
  volver: "Back",
  contacta_con_nosotros: "Contact us",
  reservar: "Reservations",
  inicio: "Home",
  eventos: "Events",
  precio: "Price",
  nombre_y_apellidos: "Name and Surname",
  telefono: "Phone number",
  consulta: "Consultation",
  aceptar_politica: "Accept privacy policy",
  enviar: "Send",
  email: "Email",
  contacto: "Contact"


};
