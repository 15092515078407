export default {
  mas_info: "Más Info",
  restaurantes: "Restaurantes",
  alojamientos: "Alojamientos",
  informacion: "Información",
  propuesta: "Propuesta",
  volver: "Volver",
  contacta_con_nosotros: "Contacta con nosotros",
  reservar: "Reservar",
  inicio: "Inicio",
  eventos: "Eventos",
  horarios: "Horarios",
  precio: "Precio",
  nombre_y_apellidos: "Nombre y apellidos",
  telefono: "Teléfono",
  consulta: "Consulta",
  aceptar_politica: "Aceptar política de privacidad",
  enviar: "Enviar",
  email: "Email",
  contacto: "Contacto"

};